import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Success({ color = 'success-green', width = '16', height = '16' }: IPropsSVGType) {
  return (
    <SVG 
      $color={color}
      $hover={color}
      $isHover={false}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 11.9443 29.0863 9.10207 27.5172 6.73152L15.5917 19.9822C14.4373 21.2648 12.4947 21.4543 11.1141 20.4188L5.66667 16.3333C4.93028 15.781 4.78105 14.7363 5.33333 14C5.88562 13.2637 6.93028 13.1143 7.66667 13.6667L13.114 17.7522L25.3567 4.14925C22.6643 1.57862 19.0165 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"/>
    </SVG>
  );
}
