import { sizes } from 'src/utils/consts/sizes';
import styled from 'styled-components';

const maxWidthAllowed = 1920;

export const ContainerLoginStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;

  max-width: ${maxWidthAllowed}px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (${sizes.min_tablet}) {
    flex-direction: row;
  }
`;

export const BackgroundImageStyle = styled.img<{ src: string }>`
  height: 100%;
  width: 100%;

  background-image: url(${(props) => props.src});
  object-fit: cover;
  object-position: 0 20%;
  @media screen and (${sizes.min_tablet}) {
    object-position: left top;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;

  height: auto;
  width: auto;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    @media screen and (${sizes.min_tablet}) {
      display: none;
    }
  }
`;

export const SectionLoginStyle = styled.div`
  z-index: 3;
  margin-top: -206px;
  background-color: #fff;
  padding: 24px 10px 30px 10px;
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: auto;
  @media screen and (${sizes.min_tablet}) {
    box-shadow: -4px 0px 16px -5px rgba(0, 0, 0, 0.6);
    margin-top: initial;
    margin-left: -16px;
    padding: 48px 60px 28px 60px;
    border-radius: 20px 0 0 20px;
    width: 536px;
  }
  @media screen and (${sizes.desktop}) {
    width: 636px;
  }
  @media screen and (${sizes.large_desktop}) {
    width: 736px;
  }
`;

export const WrapperFormStyle = styled.div`
  max-width: 380px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (${sizes.min_tablet}) {
    max-width: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;
