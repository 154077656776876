import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { social_links } from '../../utils/consts/links';

const UlStyle = styled.ul<{ $chatbots: boolean; $isActiveSidebar?: boolean }>`
  display: flex;
  gap: 32px;
  ${(props) =>
    props.$chatbots &&
    css`
      ${props.$isActiveSidebar && 'flex-direction: column;'}
      gap: 16px;
    `}
`;

const getLinks = (bots: boolean) => {
  return bots ? social_links.filter(({ isBot }) => isBot) : social_links;
};

export default function Messengers({
  chatbots = false,
  isActive = false,
}: {
  chatbots?: boolean;
  isActive?: boolean;
}) {
  const { pathname } = useLocation();
  const links = getLinks(chatbots);

  return (
    <UlStyle $chatbots={chatbots} $isActiveSidebar={isActive}>
      {links.map((data) => {
        const locates = ['catalog', 'pricelist'];
        const path = locates.some((locate) => pathname.includes(locate))
          ? `../${data.image_path}`
          : data.image_path;
        return (
          <li key={data.name}>
            <Link target="_blank" to={data.link}>
              <img src={path} alt={data.name} lang="en" />
            </Link>
          </li>
        );
      })}
    </UlStyle>
  );
}
