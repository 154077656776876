import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './routes/Routes';
import AuthProvider from './providers/AuthProvider';
import GlobalStyle from './global';

import { isMobile } from 'src/utils/helper';


export default function App() {
  const mobile = isMobile();
  return (
    <AuthProvider>
      <GlobalStyle mobile={mobile}/>
      <Routes />
      <ToastContainer autoClose={5000} />
    </AuthProvider>
  );
}
