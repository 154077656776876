import SVG from './SVG.styled';
import { IPropsSVGType } from 'src/types/svg.types';

export default function Eye({
  width = '24',
  height = '24',
  color = 'main-color',
  hover = 'gray-2',
  isHover = true,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.0006 4.6001C17.2003 4.6001 22.4006 8.83737 22.4006 12.0001C22.4006 15.1628 17.2003 19.4001 12.0006 19.4001C6.80087 19.4001 1.60059 15.1628 1.60059 12.0001C1.60059 8.83737 6.80087 4.6001 12.0006 4.6001ZM12.0006 6.4001C7.72303 6.4001 3.40059 9.92209 3.40059 12.0001C3.40059 14.0781 7.72303 17.6001 12.0006 17.6001C16.2781 17.6001 20.6006 14.0781 20.6006 12.0001C20.6006 9.92209 16.2781 6.4001 12.0006 6.4001ZM12.0006 8.6001C13.8784 8.6001 15.4006 10.1223 15.4006 12.0001C15.4006 13.8779 13.8784 15.4001 12.0006 15.4001C10.1228 15.4001 8.60059 13.8779 8.60059 12.0001C8.60059 10.1223 10.1228 8.6001 12.0006 8.6001ZM12.0006 10.4001C11.1169 10.4001 10.4006 11.1164 10.4006 12.0001C10.4006 12.8838 11.1169 13.6001 12.0006 13.6001C12.8842 13.6001 13.6006 12.8838 13.6006 12.0001C13.6006 11.1164 12.8842 10.4001 12.0006 10.4001Z" />
    </SVG>
  );
}
