import { toast } from 'react-toastify';
import {
    Container,
    IconBox,
    TextBox,
    Title,
    Description,
    CloseBtn
} from './alert.styled';

import { Error, Success, Close } from 'src/components/icons';

export const Alert = (
    { alertId, type, title, description }:
    { alertId: string, type: 'error' | 'success', title: string, description: string }) => {

    const close = () => {
        toast.dismiss(alertId);
    }

    return (
        <Container>
            <CloseBtn type="button" onClick={close}>
                <Close width='24' height='24'/>
            </CloseBtn>
            <IconBox>
                { type === 'error' && <Error color="red" width="40" height="40"/> }
                { type === 'success' && <Success color="success-green" width="40" height="40"/> }
            </IconBox>
            <TextBox>
                <Title>{ title }</Title>
                <Description className={type}>{ description }</Description>
            </TextBox>
        </Container>
    )
};