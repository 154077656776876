import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function User({
  width = '24',
  height = '24',
  color,
  hover,
  isHover,
  isActive,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/user">
        <path
          id="&#226;&#134;&#179; Icon Color"
          d="M14.8496 7.9498C14.8496 6.37499 13.5744 5.0998 11.9996 5.0998C10.4248 5.0998 9.14961 6.37499 9.14961 7.9498C9.14961 9.52462 10.4248 10.7998 11.9996 10.7998C13.5744 10.7998 14.8496 9.52462 14.8496 7.9498ZM16.6496 7.9498C16.6496 10.5187 14.5685 12.5998 11.9996 12.5998C9.43068 12.5998 7.34961 10.5187 7.34961 7.9498C7.34961 5.38087 9.43068 3.2998 11.9996 3.2998C14.5685 3.2998 16.6496 5.38087 16.6496 7.9498ZM5.89961 18.4284C5.89961 19.1972 5.80884 19.0998 6.2349 19.0998H17.7643C18.1904 19.0998 18.0996 19.1972 18.0996 18.4284C18.0996 16.5358 15.3214 15.3998 11.9996 15.3998C8.6778 15.3998 5.89961 16.5358 5.89961 18.4284ZM4.09961 18.4284C4.09961 15.1016 7.7724 13.5998 11.9996 13.5998C16.2268 13.5998 19.8996 15.1016 19.8996 18.4284C19.8996 20.164 19.2141 20.8998 17.7643 20.8998H6.2349C4.78515 20.8998 4.09961 20.164 4.09961 18.4284Z"
        />
      </g>
    </SVG>
  );
}
