import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import Backdrop from 'src/components/backdrop/Backdrop';

export default function PrivateRoute({
  children,
  view_permissions,
}: {
  children: ReactNode;
  view_permissions: string[];
}) {
  const { isUser, permissions, isLoading } = useAuth();
  const { state } = useLocation();

  if (!isUser && isLoading) return <Backdrop />;

  const access = view_permissions.length
    ? view_permissions?.some((perm) => permissions.includes(perm))
    : true;

  return isUser ? (
    access ? (
      <>{children}</>
    ) : (
      <Navigate to={state ? state : '/404'} replace />
    )
  ) : (
    <Navigate to={state ? state : '/login'} replace />
  );
}
