import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Book({
  width = '24',
  height = '24',
  color,
  hover,
  isHover,
  isActive,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4 3.89998H9.79998C8.94505 3.89998 8.35798 3.90068 7.90287 3.93786C7.45827 3.97419 7.21944 4.0408 7.0466 4.12887C6.65146 4.3302 6.3302 4.65146 6.12886 5.0466C6.04079 5.21945 5.97418 5.45827 5.93786 5.90287C5.90068 6.35798 5.89998 6.94505 5.89998 7.79998V16.3159C6.23919 16.1767 6.61062 16.1 6.99998 16.1H18.1V4.59998C18.1 4 18 3.89998 17.4 3.89998ZM4.09998 7.76228C4.09997 6.95423 4.09996 6.29337 4.14384 5.7563C4.18926 5.20038 4.28614 4.69831 4.52505 4.22942C4.89896 3.49558 5.49558 2.89896 6.22941 2.52505C6.6983 2.28614 7.20038 2.18926 7.75629 2.14384C8.29337 2.09996 8.95422 2.09997 9.76227 2.09998L17.4297 2.09998C17.6841 2.09995 17.9261 2.09993 18.1301 2.11659C18.3525 2.13476 18.6076 2.17715 18.8626 2.30707C19.2201 2.48923 19.5107 2.77989 19.6929 3.1374C19.8228 3.39237 19.8652 3.64748 19.8834 3.86989C19.9 4.07385 19.9 4.3159 19.9 4.57022L19.9 16.46C19.9 16.964 19.9 17.216 19.8019 17.4086C19.7156 17.5779 19.5779 17.7156 19.4086 17.8019C19.216 17.9 18.964 17.9 18.46 17.9H18.155C18.0786 18.2215 18.042 18.4107 18.0235 18.5989C17.9973 18.8656 17.9973 19.1343 18.0235 19.4011C18.042 19.5893 18.0786 19.7785 18.155 20.1H19C19.497 20.1 19.9 20.5029 19.9 21C19.9 21.497 19.497 21.9 19 21.9H6.99998C5.39835 21.9 4.09998 20.6016 4.09998 19C4.09998 15.2542 4.09998 11.5082 4.09998 7.76228ZM16.3118 20.1C16.2756 19.9172 16.249 19.7481 16.2322 19.5772C16.1944 19.1933 16.1944 18.8066 16.2322 18.4228C16.249 18.2519 16.2756 18.0827 16.3118 17.9H6.99998C6.39246 17.9 5.89998 18.3925 5.89998 19C5.89998 19.6075 6.39246 20.1 6.99998 20.1H16.3118Z"
      />
    </SVG>
  );
}
