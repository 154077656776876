import { IPropsSVGType } from '#types/svg.types';
import SVGStyle from './SVG.styled';

export default function Email({ color }: IPropsSVGType) {
  return (
    <SVGStyle
      $color={color}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66602 4.99992H16.3327C16.6545 4.99992 16.9338 5.18239 17.0726 5.44953L10.9616 9.52353C10.6817 9.71014 10.317 9.71014 10.0371 9.52353L3.92609 5.44952C4.06492 5.18239 4.34416 4.99992 4.66602 4.99992ZM3.83268 7.39033V14.1666C3.83268 14.6268 4.20578 14.9999 4.66602 14.9999H16.3327C16.7929 14.9999 17.166 14.6268 17.166 14.1666V7.39035L11.8861 10.9103C11.0464 11.4701 9.95236 11.4701 9.11261 10.9103L3.83268 7.39033ZM2.16602 5.83325C2.16602 4.45254 3.2853 3.33325 4.66602 3.33325H16.3327C17.7134 3.33325 18.8327 4.45254 18.8327 5.83325V14.1666C18.8327 15.5473 17.7134 16.6666 16.3327 16.6666H4.66602C3.2853 16.6666 2.16602 15.5473 2.16602 14.1666V5.83325Z"
      />
    </SVGStyle>
  );
}
