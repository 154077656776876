import SVG from './SVG.styled';

export default function News() {
  return (
    <SVG width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.005 2.80017C21.3587 2.80017 25.1995 7.17125 25.1995 12.8423V14.9751C25.1995 15.2801 25.4572 15.6842 26.1602 16.4096C26.2504 16.5026 26.3353 16.5888 26.5207 16.7766C27.9624 18.2388 28.5329 19.0812 28.5329 20.4364C28.5329 21.0291 28.4637 21.4912 28.1759 22.0448C27.5676 23.2149 26.2781 23.8668 24.3504 23.8668L21.8014 23.8682C20.9624 26.9782 18.9608 28.6671 15.9995 28.6671C13.0067 28.6671 10.9941 26.942 10.1713 23.7683L10.1969 23.8668H7.64864C5.6647 23.8668 4.36132 23.1971 3.77907 21.986C3.52446 21.4564 3.46619 21.0265 3.46619 20.4364C3.46619 19.0812 4.03669 18.2388 5.47835 16.7766C5.66378 16.5888 5.74867 16.5026 5.83883 16.4096C6.54185 15.6842 6.79952 15.2801 6.79952 14.9751V12.8423C6.79952 7.17346 10.6498 2.80017 16.005 2.80017ZM19.2875 23.8693H12.7116C13.3195 25.5315 14.3672 26.2671 15.9995 26.2671C17.6318 26.2671 18.6795 25.5315 19.2875 23.8693ZM16.005 5.20017C12.0481 5.20017 9.19952 8.43573 9.19952 12.8423V14.9751C9.19952 16.1119 8.68026 16.9263 7.5622 18.0799C7.46451 18.1807 7.3723 18.2744 7.18736 18.4616C6.19152 19.4716 5.86619 19.952 5.86619 20.4364C5.86619 20.6926 5.88425 20.8258 5.94208 20.9461C6.08515 21.2437 6.51939 21.4668 7.64864 21.4668H24.3504C25.4438 21.4668 25.8882 21.2422 26.0465 20.9378C26.1124 20.8109 26.1329 20.6742 26.1329 20.4364C26.1329 19.952 25.8075 19.4716 24.8117 18.4616C24.6267 18.2744 24.5345 18.1807 24.4368 18.0799C23.3188 16.9263 22.7995 16.1119 22.7995 14.9751V12.8423C22.7995 8.43264 19.9592 5.20017 16.005 5.20017Z"
        fill="#42434B"
      />
    </SVG>
  );
}
