import cookie from 'js-cookie';

enum KeyStorage {
  Token = 'access_token',
}

export const getItem = (): string | null => {
  return cookie.get(KeyStorage.Token) || null;
};

export const setItem = (token: string, rememberMe: boolean = false) => {
  cookie.set(KeyStorage.Token, token, rememberMe ? { expires: 7 } : undefined);
};

export const delItem = () => {
  cookie.remove(KeyStorage.Token);
};
