import { isAxiosError } from 'axios';
import { instance } from './instance';
import type { AxiosResNewsType, NewsData } from '#types/api.types';

enum apiReqNews {
  News = 'news/',
}

const getNews = async (offset: number, mode: string = 'user', limit: number) => {
  try {
    const response: AxiosResNewsType = await instance.get(apiReqNews.News, {
      params: { offset, mode, limit },
    });
    return response;
  } catch (err) {
    if (isAxiosError(err)) throw new Error(err.message);
  }
};

const getNewsById = async (id: number) => {
  try {
    const response = await instance.get(`${apiReqNews.News}/${id}`);
    return response;
  } catch (err) {
    if (isAxiosError(err)) throw new Error(err.message);
  }
};

const editNews = async (data: Partial<NewsData>) => {
  try {
    const response = await instance.put(`${apiReqNews.News}${data.id}`, data);
    return response.data;
  } catch (err) {
    if (isAxiosError(err)) throw new Error(err.message);
  }
};

type CreateNewsData = {
  active: boolean;
  end_date: string;
  start_date: string;
  text: string;
};

const createNews = async (data: Partial<NewsData>) => {
  try {
    const response = await instance.post(apiReqNews.News, data);
    return response.data;
  } catch (err) {
    if (isAxiosError(err)) throw new Error(err.message);
  }
};

const deleteNews = async (id: number) => {
  try {
    const response = await instance.delete(`${apiReqNews.News}${id}`);
    return response;
  } catch (err) {
    if (isAxiosError(err)) throw new Error(err.message);
  }
};

export { getNews, getNewsById, createNews, deleteNews, editNews };
