import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Exit({ hover = 'main-orange', isHover }: IPropsSVGType) {
  return (
    <SVG
      $color="white"
      $hover={hover}
      $isHover={isHover}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.53961 1.93702C4.42849 1.93658 4.31837 1.95813 4.21562 2.00046C4.11287 2.04278 4.01952 2.10503 3.94094 2.18361C3.86236 2.26219 3.8001 2.35554 3.75778 2.4583C3.71546 2.56105 3.6939 2.67116 3.69434 2.78229C3.69472 2.89289 3.71689 3.00233 3.75958 3.10437C3.80227 3.2064 3.86465 3.29902 3.94315 3.37694C4.02164 3.45486 4.11472 3.51656 4.21707 3.55849C4.31941 3.60043 4.42901 3.62178 4.53961 3.62135H13.6636C14.195 3.62135 14.6083 4.03467 14.6083 4.56604V11.4091C14.6087 11.5197 14.6308 11.6291 14.6735 11.7311C14.7162 11.8332 14.7786 11.9258 14.8571 12.0037C14.9356 12.0816 15.0287 12.1433 15.131 12.1852C15.2334 12.2272 15.343 12.2485 15.4536 12.2481C15.6759 12.2473 15.8888 12.1587 16.046 12.0015C16.2032 11.8443 16.2919 11.6314 16.2926 11.4091V4.56604C16.2926 3.1246 15.105 1.93702 13.6636 1.93702H4.53961ZM15.4536 13.4725C15.343 13.4721 15.2334 13.4934 15.131 13.5354C15.0287 13.5773 14.9356 13.639 14.8571 13.7169C14.7786 13.7948 14.7162 13.8874 14.6735 13.9895C14.6308 14.0915 14.6087 14.2009 14.6083 14.3115V17.3881C14.6083 17.9195 14.195 18.3328 13.6636 18.3328H9.41236C9.30123 18.3324 9.19113 18.3539 9.08838 18.3963C8.98563 18.4386 8.89227 18.5008 8.81369 18.5794C8.73512 18.658 8.67286 18.7513 8.63054 18.8541C8.58821 18.9568 8.56665 19.067 8.56709 19.1781C8.56747 19.2887 8.58963 19.3981 8.63232 19.5002C8.67501 19.6022 8.73739 19.6948 8.81589 19.7727C8.89439 19.8506 8.98747 19.9123 9.08981 19.9543C9.19215 19.9962 9.30176 20.0176 9.41236 20.0171H13.6636C15.105 20.0171 16.2926 18.8295 16.2926 17.3881V14.3115C16.2919 14.0892 16.2032 13.8763 16.046 13.7191C15.8888 13.5619 15.6759 13.4733 15.4536 13.4725Z" />
      <path d="M5.15443 2.08628C3.94654 1.55301 2.52539 2.48133 2.52539 3.80168V17.8668C2.52539 18.9541 3.17154 19.9448 4.16621 20.3839L7.6281 21.9129C8.83599 22.4461 10.2509 21.524 10.2509 20.2037V6.46802C10.2509 5.17716 9.48645 3.99983 8.30556 3.47849L5.15443 2.08628ZM4.47076 3.62766L7.6281 5.01987C8.20286 5.27349 8.5666 5.83973 8.5666 6.46802V20.2037C8.5666 20.3698 8.45767 20.4385 8.30556 20.3714L4.84989 18.8425C4.46134 18.6709 4.20972 18.2914 4.20972 17.8667V3.80159C4.20972 3.63516 4.31862 3.56051 4.47076 3.62766Z" />
      <path d="M12.861 10.5701C12.6387 10.5708 12.4258 10.6595 12.2686 10.8167C12.1114 10.9739 12.0227 11.1868 12.022 11.4091C12.0227 11.6314 12.1114 11.8444 12.2686 12.0016C12.4258 12.1588 12.6387 12.2474 12.861 12.2482H20.6301C20.8524 12.2474 21.0653 12.1588 21.2225 12.0016C21.3797 11.8444 21.4684 11.6314 21.4691 11.4091C21.4684 11.1868 21.3797 10.9739 21.2225 10.8167C21.0653 10.6595 20.8524 10.5708 20.6301 10.5701H12.861Z" />
      <path d="M18.3058 9.09068C18.149 9.24843 18.061 9.46181 18.061 9.68423C18.061 9.90666 18.149 10.12 18.3058 10.2778L20.0337 12.0056C20.1119 12.0842 20.2049 12.1466 20.3073 12.1891C20.4097 12.2317 20.5195 12.2536 20.6303 12.2536C20.7412 12.2536 20.851 12.2317 20.9534 12.1891C21.0558 12.1466 21.1488 12.0842 21.227 12.0056C21.3056 11.9274 21.3679 11.8344 21.4105 11.732C21.453 11.6296 21.4749 11.5199 21.4749 11.409C21.4749 11.2981 21.453 11.1883 21.4105 11.0859C21.3679 10.9835 21.3056 10.8906 21.227 10.8123L19.4992 9.09068C19.4209 9.0121 19.328 8.94975 19.2256 8.90721C19.1232 8.86466 19.0134 8.84277 18.9025 8.84277C18.7916 8.84277 18.6818 8.86466 18.5794 8.90721C18.4771 8.94975 18.3841 9.0121 18.3058 9.09068Z" />
      <path d="M20.6303 10.5701C20.4072 10.5686 20.1926 10.6558 20.0337 10.8126L18.3058 12.5404C18.149 12.6981 18.061 12.9115 18.061 13.1339C18.061 13.3563 18.149 13.5697 18.3058 13.7275C18.3841 13.8061 18.4771 13.8684 18.5794 13.911C18.6818 13.9535 18.7916 13.9754 18.9025 13.9754C19.0134 13.9754 19.1232 13.9535 19.2256 13.911C19.328 13.8684 19.4209 13.8061 19.4992 13.7275L21.227 12.0059C21.3056 11.9276 21.3679 11.8347 21.4105 11.7323C21.453 11.6299 21.4749 11.5201 21.4749 11.4092C21.4749 11.2983 21.453 11.1886 21.4105 11.0862C21.3679 10.9838 21.3056 10.8908 21.227 10.8126C21.0681 10.6558 20.8535 10.5686 20.6303 10.5701Z" />
    </SVG>
  );
}
