import { ReactNode, createContext, useContext, useEffect } from 'react';
import {
  useAuthActions,
  useAuthLoading,
  usePermissions,
  useIsUser,
  useUserLoading,
} from '../store/authStore';
import { FormData } from '#types/auth.types';
import Backdrop from 'src/components/backdrop/Backdrop';

interface AuthContextType {
  isUser: boolean;
  permissions: string[];
  handleLogin: (form: FormData, rememberMe: boolean) => void;
  handleLogout: () => void;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  isUser: true,
  permissions: [],
  handleLogin: (form, rememberMe) => {},
  handleLogout: () => {},
  isLoading: true,
});

export default function AuthProvider({ children }: { children: ReactNode }) {
  const { fetchUser, logout, signIn } = useAuthActions();
  const isLoading = useAuthLoading();
  const isUserLoading = useUserLoading();
  const permissions = usePermissions();
  const isUser = useIsUser();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleLogin = (form: FormData, rememberMe: boolean) => {
    signIn(form, rememberMe);
  };
  const handleLogout = () => {
    logout();
  };

  if (isUserLoading) return <Backdrop />;

  return (
    <AuthContext.Provider value={{ isUser, permissions, handleLogin, handleLogout, isLoading }}>
      {children}
      {isLoading && <Backdrop />}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
