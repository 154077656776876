import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Admin({ color, hover, isHover, isActive }: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5009 12.4286C15.1045 12.4286 17.2152 10.3179 17.2152 7.71429C17.2152 5.11066 15.1045 3 12.5009 3C9.89728 3 7.78662 5.11066 7.78662 7.71429C7.78662 10.3179 9.89728 12.4286 12.5009 12.4286ZM12.5009 10.7143C14.1578 10.7143 15.5009 9.37114 15.5009 7.71429C15.5009 6.05743 14.1578 4.71429 12.5009 4.71429C10.8441 4.71429 9.50091 6.05743 9.50091 7.71429C9.50091 9.37114 10.8441 10.7143 12.5009 10.7143ZM16.7866 23.1429C19.6269 23.1429 21.9295 20.8403 21.9295 18C21.9295 15.1597 19.6269 12.8571 16.7866 12.8571C13.9463 12.8571 11.6438 15.1597 11.6438 18C11.6438 20.8403 13.9463 23.1429 16.7866 23.1429ZM10.7714 14.2019C10.8602 14.6669 10.5551 15.1158 10.0901 15.2045C8.14251 15.5762 7.28526 16.3719 6.89363 16.9619C6.68986 17.2689 6.59145 17.5511 6.54389 17.7479C6.52011 17.8463 6.5093 17.9224 6.50447 17.9678C6.50214 17.9897 6.50123 18.0042 6.50091 18.0104V18.4285C6.50091 18.6652 6.69279 18.8571 6.92948 18.8571H9.50091C9.9743 18.8571 10.3581 19.2408 10.3581 19.7142C10.3581 20.1876 9.9743 20.5714 9.50091 20.5714H6.92948C5.74601 20.5714 4.78662 19.612 4.78662 18.4285V17.9999H5.64376C4.78662 17.9999 4.78662 17.9986 4.78662 17.9986L4.78663 17.9972L4.78664 17.9943L4.7867 17.9876L4.78701 17.9709C4.78731 17.9584 4.78784 17.9431 4.78874 17.9251C4.79055 17.8892 4.79384 17.8426 4.7998 17.7865C4.81171 17.6746 4.83438 17.524 4.87757 17.3453C4.96394 16.9878 5.13338 16.514 5.46533 16.0139C6.14512 14.9897 7.43074 13.9668 9.76883 13.5206C10.2338 13.4319 10.6827 13.7369 10.7714 14.2019ZM19.3043 16.7236L17.8705 16.5829L17.2392 15.093C17.0416 14.6267 16.535 14.6264 16.3372 15.093L15.7056 16.5829L14.2721 16.7236C13.771 16.7728 13.6186 17.2699 14.0055 17.5988L15.128 18.5534L14.7113 20.1084C14.5744 20.6191 15.0007 20.9269 15.4243 20.6208L16.7882 19.6351L18.1522 20.6208C18.5774 20.9282 19.002 20.619 18.8652 20.1084L18.4485 18.5534L19.571 17.5988C19.9562 17.2712 19.8032 16.7726 19.3043 16.7236Z"
      />
    </SVG>
  );
}
