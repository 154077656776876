import styled from 'styled-components';

const BackdropStyle = styled.div`
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default function BackDrop() {
  return <BackdropStyle />;
}
