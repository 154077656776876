import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Car({
  width = '24',
  height = '24',
  color,
  hover,
  isHover,
  isActive,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.12687 10.7817C3.24743 10.2995 3.73607 10.0063 4.21828 10.1268L4 11C4.21828 10.1268 4.21843 10.1269 4.21828 10.1268L4.22165 10.1277L4.24233 10.1326C4.26185 10.1373 4.29279 10.1444 4.3349 10.1539C4.41912 10.1727 4.54803 10.2003 4.71968 10.2339C5.063 10.3011 5.57716 10.3919 6.24659 10.4832C7.58562 10.6658 9.54481 10.85 12 10.85C14.4552 10.85 16.4144 10.6658 17.7534 10.4832C18.4228 10.3919 18.937 10.3011 19.2803 10.2339C19.452 10.2003 19.5809 10.1727 19.6651 10.1539C19.7072 10.1444 19.7381 10.1373 19.7577 10.1326L19.7784 10.1277L19.7817 10.1268C19.7816 10.1269 19.7819 10.1268 19.7817 10.1268C20.2637 10.0068 20.7526 10.2997 20.8731 10.7817C20.9937 11.2639 20.7005 11.7526 20.2183 11.8731L20 11C20.2183 11.8731 20.2185 11.8731 20.2183 11.8731L20.2134 11.8743L20.2041 11.8766L20.1725 11.8842C20.1456 11.8905 20.1072 11.8995 20.0576 11.9106C19.9582 11.9328 19.8137 11.9637 19.6259 12.0004C19.2505 12.0739 18.7022 12.1705 17.9966 12.2667C16.5856 12.4591 14.5448 12.65 12 12.65C9.45516 12.65 7.41436 12.4591 6.00339 12.2667C5.29783 12.1705 4.74949 12.0739 4.37406 12.0004C4.18634 11.9637 4.04181 11.9328 3.94244 11.9106C3.89276 11.8995 3.85436 11.8905 3.82749 11.8842L3.79587 11.8766L3.7866 11.8743L3.78361 11.8736L3.78252 11.8733C3.78233 11.8733 3.78172 11.8731 4 11L3.78252 11.8733C3.30031 11.7528 3.00632 11.2639 3.12687 10.7817ZM4.21828 10.1268C4.218 10.1268 4.2182 10.1268 4.21828 10.1268V10.1268Z"
      />
      <path d="M21 16.5C21 17.3284 20.3284 18 19.5 18H19V19.2C19 19.48 19 19.62 19.0545 19.727C19.1024 19.8211 19.1789 19.8975 19.273 19.9455C19.38 20 19.52 20 19.8 20H20.2C20.48 20 20.62 20 20.727 19.9455C20.8211 19.8975 20.8976 19.8211 20.9455 19.727C21 19.62 21 19.48 21 19.2V16.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1 19.1H19.9V18.8668C19.9677 18.8554 20.0344 18.8413 20.1 18.8244V19.1ZM21.9 16.5V19.2C21.9 19.2073 21.9 19.2146 21.9 19.2221C21.9 19.3423 21.9001 19.4806 21.8902 19.6017C21.8788 19.7407 21.8501 19.9341 21.7474 20.1356C21.6132 20.399 21.399 20.6132 21.1356 20.7474C20.9341 20.8501 20.7407 20.8788 20.6017 20.8902C20.4806 20.9001 20.3423 20.9 20.2221 20.9C20.2147 20.9 20.2073 20.9 20.2 20.9H19.8C19.7927 20.9 19.7853 20.9 19.7779 20.9C19.6577 20.9 19.5194 20.9001 19.3983 20.8902C19.2593 20.8788 19.0659 20.8501 18.8644 20.7474C18.601 20.6132 18.3868 20.399 18.2526 20.1356C18.1499 19.9341 18.1212 19.7407 18.1098 19.6017C18.0999 19.4806 18.1 19.3423 18.1 19.2221C18.1 19.2146 18.1 19.2073 18.1 19.2V17.1H19.5C19.8314 17.1 20.1 16.8313 20.1 16.5H21.9Z"
      />
      <path d="M3 16.5C3 17.3284 3.67157 18 4.5 18H5V19.2C5 19.48 5 19.62 4.9455 19.727C4.89757 19.8211 4.82108 19.8975 4.727 19.9455C4.62004 20 4.48003 20 4.2 20H3.8C3.51997 20 3.37996 20 3.273 19.9455C3.17892 19.8975 3.10243 19.8211 3.0545 19.727C3 19.62 3 19.48 3 19.2V16.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9 19.1H4.1V18.8668C4.03233 18.8554 3.96562 18.8413 3.9 18.8244V19.1ZM2.1 16.5V19.2C2.1 19.2073 2.1 19.2146 2.1 19.2221C2.09996 19.3423 2.09991 19.4806 2.1098 19.6017C2.12116 19.7407 2.14992 19.9341 2.25259 20.1356C2.38681 20.399 2.60099 20.6132 2.86441 20.7474C3.06591 20.8501 3.25928 20.8788 3.39831 20.8902C3.51935 20.9001 3.65766 20.9 3.7779 20.9C3.78534 20.9 3.79271 20.9 3.8 20.9H4.2C4.20729 20.9 4.21466 20.9 4.2221 20.9C4.34234 20.9 4.48065 20.9001 4.60169 20.8902C4.74072 20.8788 4.93409 20.8501 5.13559 20.7474C5.39901 20.6132 5.61319 20.399 5.74741 20.1356C5.85008 19.9341 5.87884 19.7407 5.8902 19.6017C5.90009 19.4806 5.90004 19.3423 5.9 19.2221C5.9 19.2146 5.9 19.2073 5.9 19.2V17.1H4.5C4.16863 17.1 3.9 16.8313 3.9 16.5H2.1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66232 4.09998H14.3377C15.2008 4.09996 15.9166 4.09994 16.5047 4.16076C17.124 4.22479 17.6776 4.36191 18.1962 4.68244C18.7149 5.00297 19.0851 5.43678 19.4193 5.96208C19.7368 6.46096 20.0569 7.10119 20.4428 7.87317L21.9 10.7875V18.9H2.1V10.7875L3.55718 7.87316C3.94314 7.10119 4.26324 6.46096 4.58067 5.96208C4.91491 5.43678 5.28513 5.00297 5.80375 4.68244C6.32237 4.36191 6.87596 4.22479 7.49527 4.16076C8.08344 4.09994 8.79923 4.09996 9.66232 4.09998ZM7.68039 5.95121C7.21635 5.99919 6.9564 6.08609 6.75007 6.21361C6.54373 6.34113 6.34976 6.53477 6.09932 6.92837C5.83768 7.33956 5.55833 7.89579 5.14663 8.71919L3.9 11.2124V17.1H20.1V11.2124L18.8534 8.71919C18.4417 7.89579 18.1623 7.33956 17.9007 6.92837C17.6502 6.53477 17.4563 6.34113 17.2499 6.21361C17.0436 6.08609 16.7836 5.99919 16.3196 5.95121C15.8348 5.90109 15.2124 5.89998 14.2918 5.89998H9.70821C8.78762 5.89998 8.16518 5.90109 7.68039 5.95121Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10.6144V10.3851C1 9.89628 1.3963 9.49998 1.88516 9.49998H4V11L3.63731 11.7254C3.54886 11.9023 3.39856 12.0406 3.21493 12.114C2.15403 12.5384 1 11.757 1 10.6144ZM23 10.6144V10.3851C23 9.89628 22.6037 9.49998 22.1148 9.49998H20V11L20.3627 11.7254C20.4511 11.9023 20.6014 12.0406 20.7851 12.114C21.846 12.5384 23 11.757 23 10.6144Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.10695 14.3883C5.1686 13.8951 5.61841 13.5453 6.11163 13.6069L8.11163 13.8569C8.60485 13.9186 8.9547 14.3684 8.89305 14.8616C8.8314 15.3548 8.38159 15.7047 7.88837 15.643L5.88837 15.393C5.39515 15.3314 5.0453 14.8816 5.10695 14.3883Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8931 14.3883C18.8314 13.8951 18.3816 13.5453 17.8884 13.6069L15.8884 13.8569C15.3952 13.9186 15.0453 14.3684 15.107 14.8616C15.1686 15.3548 15.6184 15.7047 16.1116 15.643L18.1116 15.393C18.6048 15.3314 18.9547 14.8816 18.8931 14.3883Z"
      />
    </SVG>
  );
}
