import SVG from './SVG.styled';
import { IPropsSVGType } from 'src/types/svg.types';

export default function EyeClosed({
  color = 'main-color',
  hover = 'gray-2',
  isHover = true
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5932 6.41057C11.0968 6.43599 10.6738 6.05418 10.6483 5.55777C10.6229 5.06136 11.0047 4.63834 11.5011 4.61292C14.2597 4.47167 17.075 5.56613 19.15 7.11404C20.125 7.84138 20.9375 8.66754 21.5063 9.50954C22.0753 10.3518 22.4001 11.2096 22.4001 12.0001C22.4001 12.9404 21.9361 13.981 21.1579 14.9661C20.8498 15.3562 20.2838 15.4226 19.8938 15.1145C19.5038 14.8063 19.4374 14.2404 19.7455 13.8503C20.1554 13.3315 20.6001 12.6826 20.6001 12.0001C20.6001 11.6877 20.4616 11.1787 20.0147 10.5171C19.5787 9.87162 18.9177 9.18643 18.0737 8.55683C16.2387 7.18794 13.9052 6.29218 11.5932 6.41057Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8502 16.8861C6.80033 18.3408 9.40034 19.4001 12.0001 19.4001C13.9673 19.4001 15.9299 18.7932 17.6043 17.8771L18.8637 19.1365C19.2152 19.488 19.785 19.488 20.1365 19.1365C20.488 18.785 20.488 18.2152 20.1365 17.8637L6.63649 4.3637C6.28502 4.01223 5.71517 4.01223 5.3637 4.3637C5.01223 4.71517 5.01223 5.28502 5.3637 5.63649L6.04763 6.32042C5.62895 6.56611 5.22825 6.83203 4.8502 7.11404C3.87518 7.84138 3.06265 8.66754 2.49389 9.50954C1.92492 10.3518 1.6001 11.2096 1.6001 12.0001C1.6001 12.7905 1.92492 13.6483 2.49389 14.4906C3.06265 15.3326 3.87518 16.1588 4.8502 16.8861ZM10.4001 12.0001C10.4001 12.8837 11.1164 13.6001 12.0001 13.6001C12.3724 13.6001 12.7146 13.4735 12.9869 13.2597L10.7404 11.0132C10.5267 11.2855 10.4001 11.6281 10.4001 12.0001ZM12.0001 15.4001C10.1223 15.4001 8.6001 13.8778 8.6001 12.0001C8.6001 11.1301 8.92718 10.3369 9.46346 9.73625L7.36989 7.64268C6.85696 7.91617 6.37207 8.22443 5.92647 8.55683C5.08247 9.18643 4.42149 9.87162 3.98547 10.5171C3.53859 11.1787 3.4001 11.6877 3.4001 12.0001C3.4001 12.3124 3.53859 12.8215 3.98547 13.483C4.42149 14.1285 5.08247 14.8137 5.92647 15.4433C7.63091 16.7148 9.86236 17.6001 12.0001 17.6001C13.4393 17.6001 14.9251 17.199 16.2692 16.542L14.264 14.5368C13.6634 15.0729 12.8696 15.4001 12.0001 15.4001Z"
      />
    </SVG>
  );
}
