import { create } from 'zustand';

interface IAdminStore {
  isAdminPanel: boolean;

  actions: {
    changePanel: () => void;
  };
}

const useAdminStore = create<IAdminStore>((set) => ({
  isAdminPanel: false,

  actions: {
    changePanel: () => {
      set((state) => ({ ...state, isAdminPanel: !state.isAdminPanel }));
    },
  },
}));

export const useAdminActions = () => useAdminStore((state) => state.actions);
export const useIsAdminPanel = () => useAdminStore((state) => state.isAdminPanel);
