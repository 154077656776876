import { Suspense } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

export default function BaseLayout() {
  return (
    <Container>
      <Suspense>
        <Outlet />
      </Suspense>
    </Container>
  );
}
