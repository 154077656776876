import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

export default function PrivateRoute({ children }: { children: ReactNode }) {
  const { isUser } = useAuth();
  const { state } = useLocation();

  return !isUser ? <>{children}</> : <Navigate to={state ? state : '/'} replace />;
}
