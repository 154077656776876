import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Copy({ width = '24', height = '24', color, isClick }: IPropsSVGType) {
  return (
    <SVG
      width={width}
      height={height}
      $color={color}
      $isClick={isClick}
      $hover="main-orange"
      $isHover={true}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5713 1.71436C15.6909 1.71436 16.6434 2.42994 16.9964 3.42872L8.68081 3.42864C7.15249 3.42864 6.59828 3.58777 6.03955 3.88658C5.48082 4.1854 5.04233 4.62389 4.74352 5.18262C4.4447 5.74135 4.28557 6.29556 4.28557 7.82387L4.28565 16.9966C3.28687 16.6437 2.57129 15.6911 2.57129 14.5715V6.85721C2.57129 4.01689 4.87382 1.71436 7.71415 1.71436H14.5713ZM17.9999 5.14293C19.42 5.14293 20.5713 6.29419 20.5713 7.71435V18.8572C20.5713 20.2774 19.42 21.4286 17.9999 21.4286H8.57129C7.15113 21.4286 5.99986 20.2774 5.99986 18.8572V7.71435C5.99986 6.29419 7.15113 5.14293 8.57129 5.14293H17.9999ZM17.9999 6.85721H8.57129C8.0979 6.85721 7.71415 7.24097 7.71415 7.71435V18.8572C7.71415 19.3306 8.0979 19.7144 8.57129 19.7144H17.9999C18.4732 19.7144 18.857 19.3306 18.857 18.8572V7.71435C18.857 7.24097 18.4732 6.85721 17.9999 6.85721Z" />
    </SVG>
  );
}
