import SVG from './SVG.styled';
import { IPropsSVGType } from 'src/types/svg.types';

export default function Edit({color, hover="main-orange"}: IPropsSVGType) {
  return (
    <SVG
      $isHover={true}
      $color={color}
      $hover={hover}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8776 3.17134C18.0268 2.68019 16.9787 2.68019 16.1279 3.17133C15.8121 3.35364 15.526 3.64008 15.1558 4.01078L15.1558 4.01079L15.0867 4.07994L4.33331 14.8333L4.29093 14.8757L4.29092 14.8757C3.92818 15.2384 3.68724 15.4792 3.49015 15.7505C3.09592 16.2931 2.83571 16.9213 2.73079 17.5837C2.67834 17.9149 2.67839 18.2556 2.67846 18.7686L2.67847 18.8285V19.1703L2.67835 19.2472C2.67765 19.5383 2.67693 19.8427 2.76412 20.1111C2.93723 20.6438 3.35492 21.0615 3.88769 21.2346C4.15604 21.3218 4.46046 21.3211 4.75158 21.3204L4.82847 21.3203H5.17025L5.23017 21.3203C5.7431 21.3204 6.0838 21.3204 6.415 21.268C7.07743 21.163 7.70563 20.9028 8.24824 20.5086C8.51952 20.3115 8.76039 20.0706 9.12303 19.7078L9.16541 19.6654L19.9188 8.91203L19.9879 8.84295C20.3587 8.47273 20.6451 8.18667 20.8274 7.87085C21.3185 7.02007 21.3185 5.9719 20.8274 5.12111C20.6451 4.80531 20.3587 4.51925 19.9879 4.14903L19.9188 4.07994L19.8497 4.01078C19.4795 3.64008 19.1934 3.35364 18.8776 3.17134ZM16.8778 4.47041C17.2645 4.24717 17.741 4.24717 18.1277 4.47041C18.2487 4.54026 18.3855 4.66796 18.8581 5.1406C19.3308 5.61323 19.4585 5.75005 19.5283 5.87104C19.7516 6.25777 19.7516 6.7342 19.5283 7.12093C19.4585 7.24192 19.3308 7.37874 18.8581 7.85137L18.2343 8.47518L15.5236 5.76441L16.1474 5.1406C16.62 4.66796 16.7568 4.54026 16.8778 4.47041ZM14.4629 6.82507L5.39398 15.894C4.97408 16.3139 4.82282 16.4682 4.70367 16.6322C4.44859 16.9833 4.28022 17.3898 4.21233 17.8184C4.18062 18.0186 4.17847 18.2347 4.17847 18.8285V19.1703C4.17847 19.3646 4.17873 19.4803 4.18347 19.567C4.18643 19.6212 4.19044 19.6444 4.19161 19.6503C4.21657 19.7242 4.27459 19.7822 4.34848 19.8071C4.35434 19.8083 4.3776 19.8123 4.4318 19.8153C4.51847 19.82 4.63417 19.8203 4.82847 19.8203H5.17025C5.76408 19.8203 5.98014 19.8181 6.18034 19.7864C6.60898 19.7185 7.01546 19.5502 7.36656 19.2951C7.53055 19.1759 7.68485 19.0247 8.10474 18.6048L17.1737 9.53584L14.4629 6.82507Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8776 3.17109C18.0268 2.67995 16.9787 2.67995 16.1279 3.17109C15.8121 3.3534 15.526 3.63983 15.1558 4.01054L4.29093 14.8755C3.92819 15.2381 3.68724 15.479 3.49015 15.7503C3.09592 16.2929 2.83571 16.9211 2.73079 17.5835C2.67834 17.9147 2.67839 18.2554 2.67846 18.7683L2.67847 19.17L2.67835 19.2469C2.67765 19.538 2.67693 19.8425 2.76412 20.1108C2.93723 20.6436 3.35492 21.0613 3.88769 21.2344C4.15604 21.3216 4.46046 21.3209 4.75158 21.3202L4.82847 21.32L5.23017 21.32C5.7431 21.3201 6.0838 21.3202 6.415 21.2677C7.07743 21.1628 7.70563 20.9026 8.24824 20.5084C8.51952 20.3113 8.76039 20.0703 9.12303 19.7076L19.9879 8.8427C20.3587 8.47248 20.6451 8.18642 20.8274 7.87061C21.3185 7.01982 21.3185 5.97166 20.8274 5.12087C20.6451 4.80506 20.3587 4.519 19.9879 4.14878L19.8497 4.01054C19.4795 3.63983 19.1934 3.3534 18.8776 3.17109ZM16.8778 4.47017C17.2645 4.24692 17.741 4.24692 18.1277 4.47017C18.2487 4.54002 18.3855 4.66772 18.8581 5.14036C19.3308 5.61299 19.4585 5.74981 19.5283 5.8708C19.7516 6.25752 19.7516 6.73396 19.5283 7.12068C19.4585 7.24167 19.3308 7.37849 18.8581 7.85113L18.2343 8.47494L15.5236 5.76417L16.1474 5.14035C16.62 4.66772 16.7568 4.54001 16.8778 4.47017ZM14.4629 6.82483L5.39398 15.8937C4.97408 16.3136 4.82282 16.4679 4.70367 16.6319C4.44859 16.983 4.28022 17.3895 4.21233 17.8181C4.18062 18.0184 4.17847 18.2344 4.17847 18.8282V19.17C4.17847 19.3643 4.17873 19.48 4.18347 19.5667C4.18643 19.6209 4.19044 19.6442 4.19161 19.65C4.21657 19.7239 4.27459 19.7819 4.34848 19.8069C4.35434 19.8081 4.3776 19.8121 4.4318 19.815C4.51847 19.8198 4.63417 19.82 4.82847 19.82H5.17025C5.76408 19.82 5.98014 19.8179 6.18034 19.7862C6.60898 19.7183 7.01546 19.5499 7.36656 19.2948C7.53055 19.1757 7.68485 19.0244 8.10474 18.6045L17.1737 9.5356L14.4629 6.82483Z"
      />
    </SVG>
  );
}
