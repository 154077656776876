import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Books({
  width = '24',
  height = '24',
  color,
  hover,
  isHover,
  isActive,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / books">
        <g id="Group 1000000867">
          <path
            id="Vector"
            d="M2.05921 7.56111C2.05299 7.50185 2.06057 7.44199 2.08138 7.38595C2.10219 7.32991 2.13571 7.27914 2.17945 7.23738C2.2232 7.19561 2.27604 7.16394 2.33409 7.14468C2.39214 7.12543 2.4539 7.11909 2.5148 7.12615H3.10168V5.62625C3.10168 5.43126 3.10168 5.22878 3.10168 5.22878L2.99357 5.37127C2.95193 5.41156 2.90184 5.44267 2.84671 5.46248C2.79158 5.48229 2.7327 5.49033 2.67409 5.48607C2.61547 5.4818 2.55849 5.46531 2.50702 5.43774C2.45554 5.41016 2.41079 5.37215 2.37581 5.32627L2.15959 5.09379C2.11001 5.05743 2.0698 5.01041 2.04211 4.9564C2.01441 4.9024 2 4.84289 2 4.78256C2 4.72223 2.01441 4.66272 2.04211 4.60871C2.0698 4.55471 2.11001 4.50768 2.15959 4.47133L3.00901 3.72138C3.08299 3.65005 3.17108 3.59401 3.26796 3.55664C3.36484 3.51926 3.46851 3.50132 3.57272 3.5039H4.04376C4.10598 3.49537 4.16938 3.50088 4.22906 3.51998C4.28873 3.53909 4.34308 3.57128 4.38788 3.61407C4.43268 3.65685 4.46674 3.70908 4.48741 3.76671C4.50808 3.82433 4.51481 3.88581 4.50709 3.94637V7.14864H5.1094C5.17031 7.14159 5.23207 7.14793 5.29012 7.16718C5.34816 7.18643 5.40101 7.21811 5.44475 7.25987C5.4885 7.30164 5.52201 7.35241 5.54283 7.40845C5.56364 7.46449 5.57122 7.52435 5.565 7.58361V7.92109C5.57248 7.98094 5.56584 8.04166 5.54559 8.09865C5.52534 8.15564 5.49201 8.20741 5.44811 8.25004C5.40422 8.29267 5.35091 8.32504 5.29223 8.34471C5.23355 8.36438 5.17103 8.37082 5.1094 8.36356H2.48392C2.42229 8.37082 2.35977 8.36438 2.30109 8.34471C2.24241 8.32504 2.1891 8.29267 2.14521 8.25004C2.10131 8.20741 2.06798 8.15564 2.04773 8.09865C2.02747 8.04166 2.02084 7.98094 2.02832 7.92109L2.05921 7.56111Z"
          />
          <path
            id="Vector_2"
            d="M2.02032 13.8306C2.02032 12.0383 4.01259 11.6933 4.01259 11.2058C4.01389 11.1602 4.00521 11.1148 3.98711 11.0727C3.969 11.0306 3.94191 10.9927 3.90761 10.9615C3.8733 10.9303 3.83257 10.9065 3.7881 10.8918C3.74364 10.877 3.69644 10.8716 3.64965 10.8758C3.51178 10.875 3.37891 10.926 3.279 11.0183C3.07822 11.2058 2.86974 11.2808 2.64579 11.1083L2.31374 10.8533C2.08208 10.6809 2.02803 10.4784 2.20564 10.2684C2.38698 10.0398 2.62144 9.85607 2.88985 9.73225C3.15825 9.60844 3.453 9.54802 3.75004 9.55596C3.96845 9.52923 4.19023 9.54815 4.4005 9.61146C4.61075 9.67477 4.80465 9.78101 4.96918 9.92305C5.13372 10.0651 5.26508 10.2396 5.35449 10.435C5.44388 10.6304 5.48922 10.842 5.48749 11.0558C5.48749 12.5107 3.6651 12.8182 3.58789 13.2157H5.13228C5.19289 13.2084 5.25442 13.2146 5.31218 13.2339C5.36994 13.2532 5.42241 13.285 5.4656 13.3269C5.50878 13.3689 5.54155 13.4198 5.5614 13.4759C5.58126 13.532 5.58767 13.5918 5.58016 13.6507V13.9806C5.58767 14.0395 5.58126 14.0992 5.5614 14.1553C5.54155 14.2114 5.50878 14.2624 5.4656 14.3043C5.42241 14.3463 5.36994 14.3781 5.31218 14.3974C5.25442 14.4167 5.19289 14.4229 5.13228 14.4156H2.49136C2.42982 14.4223 2.36751 14.4158 2.30882 14.3966C2.25013 14.3774 2.19646 14.346 2.15158 14.3046C2.10671 14.2632 2.0717 14.2127 2.04903 14.1567C2.02635 14.1008 2.01656 14.0407 2.02032 13.9806V13.8306Z"
          />
          <path
            id="Vector_3"
            d="M2.2088 19.5228L2.42502 19.1703C2.57946 18.9228 2.78023 18.9078 3.03506 19.0578C3.21404 19.1293 3.40526 19.1675 3.59876 19.1703C3.99259 19.1703 4.16247 19.0428 4.16247 18.8628C4.16247 18.6829 3.93853 18.5179 3.53699 18.5179H3.35166C3.2493 18.5243 3.14752 18.4986 3.06133 18.4446C2.97514 18.3906 2.90908 18.3111 2.8729 18.2179L2.83428 18.1429C2.78775 18.0503 2.77314 17.9456 2.7926 17.8443C2.81207 17.743 2.86459 17.6505 2.94239 17.5804L3.15861 17.3105C3.42888 16.9955 3.63737 16.8005 3.63737 16.8005C3.4752 16.8354 3.30919 16.8505 3.14317 16.8455H2.64123C2.58062 16.8528 2.5191 16.8465 2.46134 16.8273C2.40358 16.808 2.35111 16.7762 2.30792 16.7342C2.26473 16.6923 2.23197 16.6413 2.21211 16.5852C2.19226 16.5291 2.18585 16.4694 2.19336 16.4105V16.0805C2.18422 16.0214 2.1895 15.961 2.20878 15.9041C2.22806 15.8473 2.26079 15.7957 2.30435 15.7534C2.34791 15.7111 2.40108 15.6793 2.45959 15.6605C2.5181 15.6418 2.58031 15.6367 2.64123 15.6456H4.95784C5.01845 15.6383 5.07998 15.6445 5.13774 15.6638C5.1955 15.6831 5.24797 15.7149 5.29116 15.7568C5.33434 15.7988 5.36711 15.8497 5.38696 15.9058C5.40681 15.9619 5.41323 16.0217 5.40572 16.0805V16.2005C5.4104 16.2989 5.39509 16.3973 5.36064 16.4899C5.32619 16.5826 5.27328 16.6677 5.20494 16.7405L4.51769 17.4904C4.83264 17.555 5.1157 17.7214 5.32052 17.9626C5.52535 18.2037 5.63978 18.5053 5.6451 18.8178C5.64761 19.0531 5.59875 19.2862 5.50173 19.5018C5.40472 19.7174 5.26175 19.9106 5.08226 20.0686C4.90277 20.2266 4.69083 20.3459 4.46041 20.4186C4.22999 20.4913 3.98633 20.5157 3.74548 20.4902C3.25147 20.5011 2.76433 20.3763 2.34008 20.1303C2.28973 20.0989 2.24676 20.0576 2.21399 20.009C2.18121 19.9604 2.15938 19.9057 2.1499 19.8483C2.14042 19.791 2.14352 19.7324 2.159 19.6763C2.17447 19.6202 2.20197 19.5679 2.23969 19.5228H2.2088Z"
          />
          <path
            id="Vector_4"
            d="M21.3744 4.54639H7.61377C7.26832 4.54639 6.98828 4.81836 6.98828 5.15385V6.67625C6.98828 7.01174 7.26832 7.2837 7.61377 7.2837H21.3744C21.7199 7.2837 21.9999 7.01174 21.9999 6.67625V5.15385C21.9999 4.81836 21.7199 4.54639 21.3744 4.54639Z"
          />
          <path
            id="Vector_5"
            d="M21.3178 10.6208H7.55713C7.21168 10.6208 6.93164 10.8928 6.93164 11.2283V12.7507C6.93164 13.0862 7.21168 13.3581 7.55713 13.3581H21.3178C21.6632 13.3581 21.9432 13.0862 21.9432 12.7507V11.2283C21.9432 10.8928 21.6632 10.6208 21.3178 10.6208Z"
          />
          <path
            id="Vector_6"
            d="M21.3744 16.6956H7.61377C7.26832 16.6956 6.98828 16.9676 6.98828 17.3031V18.8254C6.98828 19.1609 7.26832 19.4329 7.61377 19.4329H21.3744C21.7199 19.4329 21.9999 19.1609 21.9999 18.8254V17.3031C21.9999 16.9676 21.7199 16.6956 21.3744 16.6956Z"
          />
        </g>
      </g>
    </SVG>
  );
}
