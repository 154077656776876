import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Dots({ color = 'main-color', width = '22', height = '20' }: IPropsSVGType) {
  return (
    <SVG 
      $color={color}
      $hover={color}
      $isHover={false}
      width={width}
      height={height}
      viewBox="0 0 28 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      
      <path xmlns="http://www.w3.org/2000/svg" d="M3.00016 16.332C4.46683 16.332 5.66683 17.532 5.66683 18.9987C5.66683 20.4654 4.46683 21.6654 3.00016 21.6654C1.5335 21.6654 0.333496 20.4654 0.333496 18.9987C0.333496 17.532 1.5335 16.332 3.00016 16.332ZM3.00016 13.6654C1.5335 13.6654 0.333496 12.4654 0.333496 10.9987C0.333496 9.53203 1.5335 8.33203 3.00016 8.33203C4.46683 8.33203 5.66683 9.53203 5.66683 10.9987C5.66683 12.4654 4.46683 13.6654 3.00016 13.6654ZM3.00016 5.66536C1.5335 5.66536 0.333496 4.46536 0.333496 2.9987C0.333496 1.53203 1.5335 0.332031 3.00016 0.332031C4.46683 0.332031 5.66683 1.53203 5.66683 2.9987C5.66683 4.46536 4.46683 5.66536 3.00016 5.66536Z"/>
    </SVG>
  );
}
