import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Error({ color = 'yellow', width = '16', height = '16' }: IPropsSVGType) {
  return (
    <SVG 
      $color={color}
      $hover={color}
      $isHover={false}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M18.357 4.66066C19.0801 3.38395 20.9194 3.38395 21.6425 4.66066L37.3134 32.3294C38.0262 33.588 37.117 35.1478 35.6706 35.1478H4.32884C2.88242 35.1478 1.97324 33.588 2.68606 32.3294L18.357 4.66066ZM18.3334 27.6091C18.3334 27.0878 18.756 26.6651 19.2773 26.6651H20.7227C21.2441 26.6651 21.6667 27.0878 21.6667 27.6091V29.0545C21.6667 29.5758 21.2441 29.9985 20.7227 29.9985H19.2773C18.756 29.9985 18.3334 29.5758 18.3334 29.0545V27.6091ZM19.2773 13.3318C18.756 13.3318 18.3334 13.7544 18.3334 14.2758V24.0545C18.3334 24.5758 18.756 24.9985 19.2773 24.9985H20.7227C21.2441 24.9985 21.6667 24.5758 21.6667 24.0545V14.2758C21.6667 13.7544 21.2441 13.3318 20.7227 13.3318H19.2773Z"/>
    </SVG>
  );
}
