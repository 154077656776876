import styled, { css } from 'styled-components';

const SVGStyle = styled.svg<{
  $color?: string;
  $hover?: string;
  $isHover?: boolean;
  $isActive?: boolean;
  $isClick?: boolean;
  $stroke?: string;
}>`
  cursor: pointer;

  ${(props) =>
    props.$stroke &&
    css`
      stroke: ${props.$stroke};
    `}
  fill: var(--${(props) => props.$color || 'main-color'});
  ${(props) =>
    props.$isActive &&
    css`
      fill: var(--${props.$hover || 'main-orange'});
    `}
  ${(props) =>
    props.$isHover &&
    css`
      transition: fill var(--animate);

      &:is(:hover, :focus) {
        fill: var(--${props.$hover || 'gray-2'});
      }
    `}
    ${(props) =>
    props.$isClick &&
    css`
      cursor: pointer;
    `};
`;

export default SVGStyle;
