import { create } from 'zustand';
import { FormData } from '#types/auth.types';
import {
  authUser,
  authLogin,
  authLogout,
  authResetPassword,
  authRestorePassword,
} from '../utils/api/auth';
import { getItem } from 'src/utils/storage';

type UserInfoType = {
  first_name: string;
  permissions: string[];
  is_client: boolean;
};

interface IAuthStore {
  access_token: string | null;
  user: UserInfoType | null;
  isLoading: boolean;
  isUserLoading: boolean;
  error: string | null;
  isSuccess: boolean;

  actions: {
    fetchUser: () => Promise<void>;
    signIn: (form: FormData, rememberMe: boolean) => Promise<void>;
    logout: () => void;
    resetPassword: (form: { email: string }) => void;
    restorePassword: (form: {
      password: string;
      confirm_password: string;
      token: string;
      id: number;
    }) => void;
  };
}

const useAuthStore = create<IAuthStore>((set) => ({
  access_token: null,
  user: null,
  isClient: true,
  isLoading: false,
  isUserLoading: true,
  error: null,
  isSuccess: false,

  actions: {
    fetchUser: async () => {
      authUser()
        .then((res) => {
          const access_token = getItem();
          const user = res?.data;
          set({ user, access_token });
        })
        .finally(() => set({ isLoading: false, isUserLoading: false }));
    },
    signIn: async (form, rememberMe) => {
      set({ isLoading: true, error: null, isSuccess: false });
      authLogin(form, rememberMe)
        .then((res) => {
          const access_token = res?.data.access_token;
          const user = {
            first_name: res?.data.first_name as string,
            permissions: res?.data.permissions ?? [],
            is_client: res?.data.is_client ?? true,
          };
          set({ access_token, user });
        })
        .catch((err) => {
          set({ error: err.message });
        })
        .finally(() => set({ isLoading: false }));
    },
    logout: () => {
      authLogout();
      set({ access_token: null, isLoading: false, error: null, user: null });
    },
    resetPassword: (form) => {
      set({ error: null });
      authResetPassword(form)
        .then(() => set({ isSuccess: true }))
        .catch((err) => set({ isSuccess: false, error: err.message }));
    },
    restorePassword: (form) => {
      set({ error: null });
      authRestorePassword(form)
        .then(() => set({ error: null }))
        .catch((err) => set({ error: err.message }));
    },
  },
}));

export const useAuthActions = () => useAuthStore((state) => state.actions);
export const useAuthLoading = () => useAuthStore((state) => state.isLoading);
export const useUserLoading = () => useAuthStore((state) => state.isUserLoading);
export const useAuthError = () => useAuthStore((state) => state.error);
export const useUser = () => useAuthStore((state) => state.user);
export const useIsUser = () => useAuthStore((state) => (state.user ? true : false));
export const usePermissions = () => useAuthStore((state) => state.user?.permissions ?? []);
export const useIsSuccess = () => useAuthStore((state) => state.isSuccess);
export const useAccessToken = () => useAuthStore((state) => state.access_token);
