import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Add({
  width = '24',
  height = '24',
  color,
  hover,
  isHover,
  isActive,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5903 2.09998C16.7582 2.09998 17.7923 2.29968 18.8665 2.87418C19.8382 3.39382 20.6061 4.16177 21.1258 5.13342C21.7003 6.20764 21.9 7.24178 21.9 9.40969V14.5903C21.9 16.7582 21.7003 17.7923 21.1258 18.8665C20.6061 19.8382 19.8382 20.6061 18.8665 21.1258C17.7923 21.7003 16.7582 21.9 14.5903 21.9H9.40969C7.24178 21.9 6.20764 21.7003 5.13342 21.1258C4.16177 20.6061 3.39382 19.8382 2.87418 18.8665C2.29968 17.7923 2.09998 16.7582 2.09998 14.5903V9.40969C2.09998 7.24178 2.29968 6.20764 2.87418 5.13342C3.39382 4.16177 4.16177 3.39382 5.13342 2.87418C6.20764 2.29968 7.24178 2.09998 9.40969 2.09998H14.5903ZM14.5903 3.89998H9.40969C7.51351 3.89998 6.7601 4.04547 5.9823 4.46144C5.32433 4.81333 4.81333 5.32433 4.46144 5.9823C4.04547 6.7601 3.89998 7.51351 3.89998 9.40969V14.5903C3.89998 16.4864 4.04547 17.2398 4.46144 18.0177C4.81333 18.6756 5.32433 19.1866 5.9823 19.5385C6.7601 19.9545 7.51351 20.1 9.40969 20.1H14.5903C16.4864 20.1 17.2398 19.9545 18.0177 19.5385C18.6756 19.1866 19.1866 18.6756 19.5385 18.0177C19.9545 17.2398 20.1 16.4864 20.1 14.5903V9.40969C20.1 7.51351 19.9545 6.7601 19.5385 5.9823C19.1866 5.32433 18.6756 4.81333 18.0177 4.46144C17.2398 4.04547 16.4864 3.89998 14.5903 3.89998ZM12 7.49998C12.4588 7.49998 12.8374 7.84331 12.893 8.28708L12.9 8.39998L12.899 11.1H15.6C16.0588 11.1 16.4374 11.4433 16.493 11.8871L16.5 12C16.5 12.4588 16.1566 12.8374 15.7129 12.893L15.6 12.9H12.899L12.9 15.6C12.9 16.0588 12.5566 16.4374 12.1129 16.493L12 16.5C11.5412 16.5 11.1625 16.1566 11.107 15.7129L11.1 15.6L11.099 12.9H8.39998C7.94115 12.9 7.56252 12.5566 7.50699 12.1129L7.49998 12C7.49998 11.5412 7.84331 11.1625 8.28708 11.107L8.39998 11.1H11.099L11.1 8.39998C11.1 7.94115 11.4433 7.56252 11.8871 7.50699L12 7.49998Z" />
    </SVG>
  );
}
