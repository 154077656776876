import axios from 'axios';

export const instance = axios.create({
  baseURL: '/api/v1',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const authorizationAxios = (token: string | null) =>
  (instance.defaults.headers.common['X-Authorization'] = token ? token : null);
