import styled from 'styled-components';

export const ButtonHoverStyle = styled.button`
  color: var(--white);
  background-color: var(--main-orange);
  font-weight: 700;
  border: 1px solid var(--main-orange);
  transition: all 0.25s linear;

  &:is(:hover, :focus) {
    color: var(--white);
    background-color: var(--orange-2);
  }
`;

export const ButtonHoverRevertStyle = styled.button`
  color: var(--main-orange);
  background-color: var(--white);
  font-weight: 700;
  border: 1px solid var(--main-orange);
  transition: all 0.25s linear;

  &:is(:hover, :focus) {
    color: var(--main-orange);
    background-color: var(--slightly-sky-blue);
  }
`;