import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Users({
  width = '24',
  height = '24',
  color,
  hover,
  isHover,
  isActive,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3331 10.7995C11.3331 8.22215 13.4224 6.13281 15.9997 6.13281C18.5771 6.13281 20.6664 8.22215 20.6664 10.7995C20.6664 13.3768 18.5771 15.4661 15.9997 15.4661C13.4224 15.4661 11.3331 13.3768 11.3331 10.7995ZM15.9997 13.0661C14.7479 13.0661 13.7331 12.0513 13.7331 10.7995C13.7331 9.54763 14.7479 8.53281 15.9997 8.53281C17.2516 8.53281 18.2664 9.54763 18.2664 10.7995C18.2664 12.0513 17.2516 13.0661 15.9997 13.0661Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0002 17.4662C13.9949 17.4662 12.0995 17.9377 10.6641 18.8196C9.22696 19.7025 8.13358 21.0893 8.13358 22.8724C8.13358 23.9518 8.50488 24.9156 9.26853 25.5962C10.0128 26.2595 10.9937 26.5329 12.0003 26.5329H20.0002C21.0068 26.5329 21.9877 26.2595 22.732 25.5962C23.4956 24.9156 23.8669 23.9518 23.8669 22.8724C23.8669 21.0893 22.7735 19.7025 21.3364 18.8196C19.901 17.9377 18.0056 17.4662 16.0002 17.4662ZM10.5336 22.8724C10.5336 22.1827 10.9407 21.4664 11.9205 20.8645C12.902 20.2614 14.34 19.8662 16.0002 19.8662C17.6605 19.8662 19.0985 20.2614 20.08 20.8645C21.0598 21.4664 21.4669 22.1827 21.4669 22.8724C21.4669 23.3869 21.3049 23.6533 21.1352 23.8045C20.9461 23.973 20.5937 24.1329 20.0002 24.1329H12.0003C11.4068 24.1329 11.0544 23.973 10.8653 23.8045C10.6956 23.6533 10.5336 23.3869 10.5336 22.8724Z"
      />
      <path d="M7.87096 18.2662C7.87096 17.6034 7.33371 17.0662 6.67096 17.0662C3.99529 17.0662 1.4043 18.7675 1.4043 21.5424C1.4043 23.2264 2.56451 25.1995 4.88044 25.1995C5.54318 25.1995 6.08043 24.6623 6.08043 23.9995C6.08043 23.3368 5.54318 22.7995 4.88044 22.7995C4.51785 22.7995 4.28067 22.6605 4.11332 22.4609C3.92685 22.2384 3.8043 21.9051 3.8043 21.5424C3.8043 20.5677 4.79001 19.4662 6.67096 19.4662C7.33371 19.4662 7.87096 18.9289 7.87096 18.2662Z" />
      <path d="M8.41912 7.27824C9.0752 7.18451 9.68304 7.64038 9.77677 8.29646C9.87051 8.95254 9.41464 9.56038 8.75856 9.65412C7.94797 9.76992 7.3334 10.463 7.3334 11.2881C7.3334 12.1938 8.0734 12.9391 9.00007 12.9391C9.66281 12.9391 10.2001 13.4764 10.2001 14.1391C10.2001 14.8019 9.66281 15.3391 9.00007 15.3391C6.7603 15.3391 4.9334 13.5316 4.9334 11.2881C4.9334 9.24181 6.45291 7.55915 8.41912 7.27824Z" />
      <path d="M24.1285 18.2662C24.1285 17.6034 24.6658 17.0662 25.3285 17.0662C28.0042 17.0662 30.5952 18.7675 30.5952 21.5424C30.5952 23.2264 29.435 25.1995 27.119 25.1995C26.4563 25.1995 25.919 24.6623 25.919 23.9995C25.919 23.3368 26.4563 22.7995 27.119 22.7995C27.4816 22.7995 27.7188 22.6605 27.8862 22.4609C28.0726 22.2384 28.1952 21.9051 28.1952 21.5424C28.1952 20.5677 27.2095 19.4662 25.3285 19.4662C24.6658 19.4662 24.1285 18.9289 24.1285 18.2662Z" />
      <path d="M23.581 7.27824C22.9249 7.18451 22.3171 7.64038 22.2234 8.29646C22.1296 8.95254 22.5855 9.56038 23.2416 9.65412C24.0522 9.76992 24.6667 10.463 24.6667 11.2881C24.6667 12.1938 23.9267 12.9391 23.0001 12.9391C22.3373 12.9391 21.8001 13.4764 21.8001 14.1391C21.8001 14.8019 22.3373 15.3391 23.0001 15.3391C25.2398 15.3391 27.0667 13.5316 27.0667 11.2881C27.0667 9.24181 25.5472 7.55915 23.581 7.27824Z" />
    </SVG>
  );
}
