import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    padding: 10px;
    position: relative;
`;

export const IconBox = styled.div`
  margin: 0px 5px 0px 0px;
`;

export const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 5px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const Description = styled.div`
  font-size: 12px;
  color: var(--main-color);

  &.error {
    color: var(--red);
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 13px;
  right: 11px;
  border: none;
  outline: none;
  background-color: transparent;
`;