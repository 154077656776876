import SVG from './SVG.styled';
import { IPropsSVGType } from 'src/types/svg.types';

export default function Help({
  color = 'main-color',
  hover = "main-orange",
  isHover = true,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM12 2.8C6.91898 2.8 2.8 6.91898 2.8 12C2.8 17.081 6.91898 21.2 12 21.2C17.081 21.2 21.2 17.081 21.2 12C21.2 6.91898 17.081 2.8 12 2.8ZM11.8023 16.5743C12.4094 16.5743 12.9016 17.0665 12.9016 17.6736V17.7007C12.9016 18.3078 12.4094 18.8 11.8023 18.8C11.1952 18.8 10.703 18.3078 10.703 17.7007V17.6736C10.703 17.0665 11.1952 16.5743 11.8023 16.5743ZM11.739 5.8C13.7065 5.8 15.3 7.42981 15.3 9.44202C15.3 10.2433 14.9795 10.9717 14.4721 11.4907L13.6708 12.3283C13.1943 12.8224 12.8507 13.2511 12.7057 13.9997C12.6923 14.0687 12.6826 14.172 12.6765 14.3096C12.6556 14.7841 12.2649 15.158 11.79 15.158C11.2931 15.158 10.8903 14.7552 10.8903 14.2584L10.8904 14.2562C10.891 14.0023 10.8988 13.8361 10.9137 13.7575C11.0514 13.0319 11.4007 12.3808 11.8903 11.8731L12.9943 10.7258C13.3102 10.4041 13.5195 9.9428 13.5195 9.44202C13.5195 8.44047 12.7183 7.62101 11.739 7.62101C11.1167 7.62101 10.5664 7.95188 10.2478 8.45009C10.1871 8.54495 10.1114 8.69405 10.0206 8.89737C9.82546 9.33463 9.32851 9.55024 8.87585 9.39407C8.42804 9.23957 8.19027 8.75131 8.34477 8.3035C8.34958 8.28957 8.35475 8.27576 8.36025 8.26208C8.42491 8.10195 8.48697 7.96067 8.54642 7.83824C9.15791 6.57896 10.3093 5.8 11.739 5.8Z" />
    </SVG>
  );
}