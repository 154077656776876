export const social_links = [
  {
    name: 'facebook',
    image_path: 'images/icon_facebook.png',
    link: 'https://www.facebook.com/startex02',
    isBot: false,
  },
  {
    name: 'instagram',
    image_path: 'images/icon_insta.png',
    link: 'https://www.instagram.com/star_tex_mats/',
    isBot: false,
  },
  {
    name: 'telegram',
    image_path: 'images/icon_telegram.png',
    link: 'https://t.me/startexb2b_bot',
    isBot: true,
  },
  {
    name: 'viber',
    image_path: 'images/icon_viber.png',
    link: 'viber://pa?chatURI=startexb2b',
    isBot: true,
  },
];
