import SVG from './SVG.styled';

export default function Calendar() {
  return (
    <SVG width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="UI icon/calendar/light">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83329 1.66675C6.29353 1.66675 6.66663 2.03984 6.66663 2.50008V3.33341H13.3333V2.50008C13.3333 2.03984 13.7064 1.66675 14.1666 1.66675C14.6269 1.66675 15 2.03984 15 2.50008V3.33341H15.8333C17.214 3.33341 18.3333 4.4527 18.3333 5.83342V15.8334C18.3333 17.2141 17.214 18.3334 15.8333 18.3334H4.16663C2.78591 18.3334 1.66663 17.2141 1.66663 15.8334V5.83342C1.66663 4.4527 2.78591 3.33341 4.16663 3.33341H4.99996V2.50008C4.99996 2.03984 5.37306 1.66675 5.83329 1.66675ZM13.3333 5.00008V5.83342C13.3333 6.29365 13.7064 6.66675 14.1666 6.66675C14.6269 6.66675 15 6.29365 15 5.83342V5.00008H15.8333C16.2935 5.00008 16.6666 5.37318 16.6666 5.83342V8.33342H3.33329V5.83342C3.33329 5.37318 3.70639 5.00008 4.16663 5.00008H4.99996V5.83342C4.99996 6.29365 5.37306 6.66675 5.83329 6.66675C6.29353 6.66675 6.66663 6.29365 6.66663 5.83342V5.00008H13.3333ZM3.33329 10.0001V15.8334C3.33329 16.2937 3.70639 16.6667 4.16663 16.6667H15.8333C16.2935 16.6667 16.6666 16.2937 16.6666 15.8334V10.0001H3.33329Z"
          fill="#636366"
        />
      </g>
    </SVG>
  );
}
