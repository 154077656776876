import SVG from './SVG.styled';

export default function Burger() {
  return (
    <SVG
      $isHover={true}
      $isClick={true}
      $color="main-orange"
      $hover="orange-2"
      width="30"
      height="30"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="burger">
        <g id="Group 609">
          <path id="Rectangle" d="M2.07715 6.48071H27.9233V9.24994H2.07715V6.48071Z" />
          <path id="Rectangle_2" d="M2.07715 13.8652H27.9233V16.6345H2.07715V13.8652Z" />
          <path id="Rectangle_3" d="M2.07715 21.2498H27.9233V24.019H2.07715V21.2498Z" />
        </g>
      </g>
    </SVG>
  );
}
